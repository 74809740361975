$primary-color: #171dc1;
$dark-color: #6b6b6b;
$secondary-color: #435f88;
$accept-color: #1565ce;
$cancel-color: #b42121;
@import "../../../node_modules/primereact/resources/primereact.min.css";
@import "../../../node_modules/primeflex/primeflex.scss";
@import "../../../node_modules/primeicons/primeicons.css";
@import url('https://fonts.cdnfonts.com/css/segoe-ui-variable-static-display');

* {
  margin: 0;
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
}

.menTam {
  width: 100%;
  overflow: auto;
}

.container {
  overflow-x: hidden;
}

#root {
  overflow-x: hidden;
}

.Segoe {
  font-family: "Segoe UI";
}

body {
  background: #eeeeee;
}

.row {
  display: flex;
}

.col_1 {
  width: 100%;
}

.col_2 {
  width: 50%;
}
.support-col-text{
  width: 15%;
}
.support-col-input{
  width: 75%;
}


.col_3 {
  width: 33%;
}

.col_4 {
  width: 25%;
}

.mxw600px {
  width: 600px;
  max-width: 100%;
}

.mxw800px {
  width: 800px;
  max-width: 100%;
}

.mxw1000px {
  width: 1000px;
  max-width: 100%;
}

.row_table {
  margin: 5px;
  background: #ffffff;
  transition: 0.5s;
  animation-fill-mode: backwards !important;
}

.row_table.active {
  box-shadow: 0px 1px 5px rgb(0 0 0 / 28%);
  background: rgb(201 201 201 / 35%);
  transition: 0.5s;
  animation-fill-mode: backwards;
}

.btn_accept {
  background: $accept-color;
  padding: 10px 30px;
}

.btn_accept2 {
  background: $accept-color;
  margin-top: -5%;
  margin-left: 5%;
}

.titleTipeTable {
  background: $primary-color;
  color: white;
  padding: 10px 30px;
}

.p-button:hover {
  font-family: inherit;
  cursor: pointer;
  background-color: #e9edf5;
}

.btn-editUser {
  padding: 0;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  margin-left: 22%;
  padding-left: 10px;
  text-align: left;
  padding-right: 25px;
  width: 35%;
  height: 25px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 62px;
  transition: 0.8s;

  .fa-pen-to-square {
    margin-right: 20%;
    margin-left: 5%;
    color: #1561ff;
    /* Cambia el color a rojo */
  }

  .texto {
    color: #2b2b2b;
  }
}



.btn-editUser-edit {
  padding: 0;
  background: #35e08d 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  margin-left: 22%;
  padding-left: 10px;
  text-align: left;
  padding-right: 25px;
  width: 35%;
  height: 25px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 62px;
  transition: 0.8s;

  .fa-pen-to-square {
    margin-right: 20%;
    margin-left: 5%;
    color: #2b2b2b;
    /* Cambia el color a rojo */
  }

  .texto {
    color: #2b2b2b;
    font-weight: bold;
  }
}

.btn-editUser:hover {
  background-color: #35e08d;

  .fa-pen-to-square {
    color: #2b2b2b;
    /* Cambia el color a rojo */
  }

  .texto {
    color: #2b2b2b;
    font-weight: bold;
  }
}

.btn_lista_toggle_none{
  background-color: white !important;
  border: 2px slid #0740b9 !important;
  color: #0740b9 !important;
  transition: 0.8s;
}
.btn_lista_toggle{
  background-color:#0740b9 !important;
  border: 2px slid white !important;
}

.btn_lista_toggle_none:hover{
  background-color:#000 !important;
  border: 2px slid white !important;
  color: white !important;
  transform: scale(1.1);
}

.tag_estatus_certificacion{
  transform: scale(1.3);
}
.table-container {
  width: 100%;
  border-radius: 10px;
  max-height: 420px;
  /* Ajusta la altura de la tabla-container según tus necesidades */
  overflow: auto;

  table {
    border-collapse: collapse;
    position: relative;
    z-index: 2;
  }

  th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  tbody {

    /* Ajusta la altura del cuerpo de la tabla según tus necesidades */
    overflow: auto;
  }

  .btn_calificar {
    padding: 0;
    background: #f5f5f5 0% 0% no-repeat padding-box;
    border: 1px solid var(--unnamed-color-707070);
    margin-left: 0%;
    padding-left: 0;
    text-align: left;
    color: #000;
    text-align: center;
    padding-right: 0;
    font-weight: bold;
    font-size: 12px;
    width: 100%;
    height: 31px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 0px;
    transition: 0.8s;
  }

  .btn_calificar:hover {
    background-color: #35e08d;
    transform: scale(1.2);
    border-radius: 5px;
  }

  .p-progressbar {
    border: 1px solid #707070;
    transition: 0.8s;

    .p-progressbar-value {
      background: #35e08d;
      transition: 0.8s;
    }

    .p-progressbar-label {
      color: #2b2b2b;
      font-size: 12px;
      text-align: center;
      font-weight: bold;
      transition: 0.8s;
    }
  }

  .p-progressbar:hover {
    background-color: #414040;

    .p-progressbar-value {
      background: #3126a6;
    }

    .p-progressbar-label {
      color: #fff;
      font-size: 12px;
      text-align: center;
      font-weight: bold;
    }
  }

  .p-inputswitch-slider {
    border: none;
    border-radius: 10px;
    transition: 0.8s;
  }

  .p-inputswitch-slider:hover {
    transform: scale(1.2);
  }

  /* Cambiar el color del botón deslizante */
  .p-inputswitch .p-inputswitch-slider {
    background-color: #ea2812; /* Cambiar a tu color deseado */
    transition: 0.8s;
  }

  /* Cambiar el color del contorno del botón deslizante cuando está activado (ON) */
  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background-color: #19ea12; /* Cambiar a tu color deseado */
    transition: 0.8s;
  }

  .p-inputswitch:hover .p-inputswitch-slider {
    background-color: #ca301f; /* Cambiar a tu color deseado */
  }

  /* Cambiar el color del contorno del botón deslizante cuando está activado (ON) */
  .p-inputswitch.p-inputswitch-checked:hover .p-inputswitch-slider {
    background-color: #1cc716; /* Cambiar a tu color deseado */
  }
}

.quitaEstilos{
  padding: 15px;
  // background: #35e08d 0% 0% no-repeat padding-box;
  // border: 1px solid #707070;
  margin: 3%;
  padding-left: 20px;
  text-align: left;
  text-align: center;
  padding-right: 20px;
  // width: 35%;
  // height: 25px;
  // box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  transition: 0.8s;
}

.texto{
  color: white;
  font-size: 15px;
  margin-top: 4%;
}
.switch_on {
  background: #19ea12;
  transition: 0.8s;
}

.switch_off {
  background: #ea2812;
  transition: 0.8s;
}

.switch_off:hover {
  background: #ff2812;
}

.switch_on:hover {
  background: #19ff12;
}

.switch_icon {
  font-size: 1.7em;
}
.switch_icon:hover {
  transform: scale(1.3);
}
.buttons_de_calificaiones-none {
  display: flex;
  opacity: 0;
  margin-left: 50%;
  width: 50%;
}

.icon_trash {
  color: #e3a81d;
  margin-left: 10%;
  transition: 0.8s;
}

.icon_trash {
  color: #ffa81d;
}

.botonesGral-none {
  display: none;
  opacity: 0;
  transition: 0.8s;

  .btn_cancelar-none {
    padding: 0;
    background: #f5f5f5 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    margin-left: 0%;
    padding-left: 0;
    text-align: left;
    color: #000;
    text-align: center;
    padding-right: 0;
    font-weight: bold;
    font-size: 12px;
    width: 100%;
    height: 31px;
    opacity: 0;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 0px;
    transition: 0.8s;
  }

  .btn_edit-none {
    padding: 0;
    background: #35e08d 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    margin-left: 10%;
    padding-left: 0;
    text-align: left;
    color: #000;
    text-align: center;
    padding-right: 0;
    font-weight: bold;
    font-size: 12px;
    width: 100%;
    height: 31px;
    opacity: 0;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 0px;
    transition: 0.8s;
  }

  .buttons_de_calificaiones-none {
    display: flex;
    opacity: 0;
    margin-left: 50%;
    width: 50%;
  }
}

.botonesGral {
  background-color: transparent;
  margin-top: 5%;
  opacity: 1;
  transition: 0.7s;
  width: 100%;

  .buttons_de_calificaiones {
    display: flex;
    margin-left: 50%;
    width: 50%;

    .btn_cancelar {
      padding: 0;
      background: #f5f5f5 0% 0% no-repeat padding-box;
      border: 1px solid #707070;
      margin-left: 0%;
      padding-left: 0;
      text-align: left;
      color: #000;
      text-align: center;
      padding-right: 0;
      font-weight: bold;
      font-size: 12px;
      width: 100%;
      opacity: 1;
      height: 31px;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 0px;
      transition: 0.8s;
    }

    .btn_edit {
      padding: 0;
      background: #35e08d 0% 0% no-repeat padding-box;
      border: 1px solid #707070;
      margin-left: 10%;
      padding-left: 0;
      text-align: left;
      color: #000;
      text-align: center;
      padding-right: 0;
      font-weight: bold;
      font-size: 12px;
      width: 100%;
      opacity: 1;
      height: 31px;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 0px;
      transition: 0.8s;
    }

    .btn_edit:hover {
      background-color: #1561ff;
      color: white;
      border-radius: 62px;
    }

    .btn_cancelar:hover {
      background-color: #ff2c15;
      color: white;
      border-radius: 62px;
    }
  }
}

.btn_De_acciones {
  .fa-pen-to-square {
    color: #707070;
    transition: 0.5s;
  }

  .fa-message {
    color: #707070;
    transition: 0.5s;
  }

  .fa-eye {
    color: #707070;
    transition: 0.5s;
  }

  .fa-trash-can {
    color: #707070;
    transition: 0.5s;
  }

  .fa-pen-to-square:hover {
    color: #19ea12;
    transition: 0.5s;
  }

  .fa-message:hover {
    color: #eadc12;
    transition: 0.5s;
  }

  .fa-eye:hover {
    color: #12a2ea;
    transition: 0.5s;
  }

  .fa-trash-can:hover {
    color: #ea1212;
    transition: 0.5s;
  }
}

.negritas {
  color: #000;
  text-decoration: none;
  font-kerning: none;
  font-weight: bold;
}

.puntuacion {
  border: none;
  text-align: center;
  color: #2b2b2b;
  width: 80%;
  transition: 1s;
  font-size: 17px;
  background-color: transparent;
}

.puntuacion-edit {
  text-align: center;
  border-radius: 5px;
  color: #2b2b2b;
  width: 80%;
  font-size: 17px;
  transition: 1s;
  background-color: transparent;
  border: 1px solid #ff8a8a;
}

.btn-acciones {
  margin-left: 10%;
  font-size: 15px;
  transition: 0.8s;
}

.btn-acciones:hover{
  transform: scale(1.4);
}

.contenedor-detabla {
  max-height: 300px;
  /* Establece la altura máxima de la tabla */
  max-width: 100%;
  overflow: auto;
}

.titleStatus {
  background-color: #f5f8ff;
  text-decoration: underline;
  font-weight: bold;
  color: #000;
  font-size: 15px;
}

.celdaConLinea {
  border-bottom: 2px solid #00000029;
}

.btn_cancel {
  background: $cancel-color;
}

.color_cancel {
  color: $cancel-color;
}

.color_accept {
  color: $primary-color;
}

.color_see {
  color: yellow;
}

.colum {
  padding: 10px;
  max-width: 100%;
  width: 200px;
}

.colum_2 {
  padding: 10px;
  max-width: 100%;
  width: 50px;
}

.table {
  border-radius: 3px;
  background: white;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.112);
  padding: 10px;
  border: none;
  border-spacing: 0px 5px;
  height: min-content;
}

.h100vh {
  height: 100vh;
}

.w100 {
  width: 100%;
}

.w50 {
  width: 50%;
}
.w75 {
  width: 75%;
}

.w25 {
  width: 25%;
}

.flex {
  display: flex;
}

.tabla2 {
  margin-left: 2%;
}

.encab2 {
  background-color: #0840b9;
  color: white;
}

.encabezadoCajaChina {
  background-color: #2b2b2b;
  color: white;
}

.w85 {
  width: 85%;
}

.h100 {
  height: 100%;
}

.wrap {
  flex-wrap: wrap;
}

.vcenter {
  align-items: center;
}

.hcenter {
  justify-content: center;
}

.hleft {
  justify-content: left;
}

.hright {
  justify-content: right;
}
.jc-end{
  justify-content: end;
}

.bold {
  font-weight: bold;
}

.lighter {
  font-weight: lighter;
}

.f_medium {
  font-size: medium;
}

.f_large {
  font-size: large;
}

.f_larger {
  font-size: larger;
}

.f_xx_large {
  font-size: xx-large;
}

.f_50 {
  font-size: 50px;
}

.f_40 {
  font-size: 40px;
}

.t_center {
  text-align: center;
}

.f_small {
  font-size: small;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.box {
  border-radius: 10px;
  padding: 30px;
}

.alice-carousel__wrapper {
  padding: 10px;
}

.search {
  border-radius: 5px;
  border: 1px solid rgba(72, 72, 72, 0.329);
}

.dark {
  background: #000;
}

.block {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 99;
}

.btn_agregar {
  width: 50%;
  height: 30px;
  padding: 0;
  text-align: center;
  padding-right: 0;
  font-weight: bold;
  margin-bottom: -10%;
  font-size: 12px;
  transition: 0.8s;
}

.btn_agregar:hover {
  background-color: #f5f5f5;
  color: #000;
  transform: scale(1.1);
  border: 1px solid rgba(72, 72, 72, 0.329);
}

.grafico_Calificacion{
  height: 100px;
}
.box_c {
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
}

.box_header {
  background: #6e6e6e;
  padding: 10px 27px;
  border: #707070 1px solid;
  border-radius: 10px;
}

.table_header {
  background: #062d75;
  border-radius: 10px;
  padding: 20px 20px;
  height: 50px;
  position: sticky;
  top: 0;
  z-index: 3;
}

.column {
  padding: 10px 20px;
  height: 50px;
}

.input_actividad {
  border: 1px solid rgb(146, 146, 146);
  padding: 5px;
  border-radius: 5px;
}

textarea.input_actividad {
  height: 20px;
}

.input_actividad.required {
  border: 1px solid rgb(255, 136, 136);
  padding: 5px;
  border-radius: 5px;
}

.input_actividad.w100px {
  max-width: 100px;
}

.input_actividad.w160px {
  max-width: 160px;
}

.input_actividad.w50px {
  max-width: 50px;
}

.row_table {
  transition: 0.5;
}

.row_table:hover {
  background: rgba(137, 137, 137, 0.159);
  box-shadow: 0px 0px 5px rgba(147, 147, 147, 0.257);

  transition: 0.5;
}

.notification {
  padding: 15px;
}

.icon_box {
  transition: 0.5s;
}

.icon_box.active {
  transform: rotate(180deg);
  transition: 0.5s;
}

.info_box {
  position: absolute;
  left: 20px;
  width: 300px;
  background: #fff;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.217);
}

.hover_green {
  transition: 0.5s;
}

.hover_green.active,
.hover_green:hover {
  color: rgb(51, 225, 144);
  transition: 0.5s;
}

.hover_red {
  transition: 0.5s;
}

.hover_red:hover {
  color: rgb(225, 51, 51);
  transition: 0.5s;
}

.c_active {
  color: #33e190;
  background: black;
  border-radius: 50%;
  transition: 0.5s;
}

.date_row {
  background: #0740b9;
  border-radius: 10px;
  padding: 10px 20px;
  transition: 0.5s;
}

.mtop_7px {
  margin-top: 7px;
}

.date_row.active {
  background: #32c781;
  transition: 0.5s;
}

.progress {
  margin: 0 auto;
  border: 1px solid #7b7b7b;
  max-width: 130px;
  border-radius: 3px;
  transition: 0.5s;
}

.progress-bar {
  width: 60%;
  height: 15px;
  background: #34e08d;
}

.p-fileupload .p-fileupload-advanced .p-component{
  display: flex;
  flex-wrap: wrap;
}

.p-fileupload-content,.p-fileupload-buttonbar{
  width: 100%;
}

.fileComponent{
  padding: 10px;
  border-radius: 4px;
  background: #f4f4f48c;
  height: 150px;
  width: 150px;
  border: 1px solid rgba(147, 147, 147, 0.257);
  transition: 0.5s;

}

.line {
  background: #0061FF;
  border-radius: 0px;
  height: 8px;
  position: sticky;
  top: 0;
  z-index: 3;
}
.mb-5px{
  margin-bottom: 5px;
}
.mt-20px{
  margin-top: 20px;
}
.bolder{
  color: #000;
  text-decoration: none;
  font-kerning: none;
  font-weight: bold;
}
.w33{
  width: 33%;
}
.w-auto{
  width: auto;
}
.flex-break {
  flex-basis: 100%;
  height: 0;
}
.flex-grow-1{
  flex-grow: 1;
  width: auto;
}
.flex-grow-2{
  flex-grow: 2;
  width: auto;
}
.flex-grow-3{
  flex-grow: 3;
  width: auto;
}
.flex-grow-auto{
  flex-grow: auto;
  width: auto;
}
.flex-1{
  flex: 1 1 !important;
}
.h40px{
  height: 40px;
}

.font-primary{
  font-family: "Segoe UI Variable Static Display";
}

.inline-flex {
  display: inline-flex;
}

.opacity-0{
  opacity: 0;
}

.column-5{
  padding: 5px 5px;
  height: 50px;
}
.m-0{
  margin: 0;
}
.my-auto{
  margin-top: auto;
  margin-bottom: auto;
}

.lock-user {
  cursor: pointer;
  font-size: 40px;
  color: #0161fe;
}
.lock-filter {
  padding: 7px 20px;
  height: 40px;
  z-index: 4;
}
.lock-f10{
  font-size: 10px;
}
.lock-f15{
  font-size: 15px;
}
.lock-filter-select{
  top: 100%;
  width: auto;
  padding: 0px 10px;
  left: 0;
  background: #efefef;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2588235294);
  margin: 5px 0;
  border-radius: 3px;
  z-index: 1;
}
.p5{
  padding: 5px;
}
.icon-color-second{
  color: #0740b9;
}
.modal-mini{
  width: 100% !important;
  height: 680px !important;
}